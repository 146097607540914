<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import {
  fetchUserStructuresTypesApi,
  fetchDirectionsListApi,
  fetchDivisionsListApi,
  fetchDepartmentsListApi,
  fetchServicesListApi,
  fetchTakeAwayMealRequestDestinationsApi,
  fetchTakeAwayMealRequestExternalDestinationsApi,
  fetchMealsTypesApi,
  fetchIdentityDocTypesApi,
  fetchSupportTypesApi,
} from "@/api/common";
export default {
  page: {
    title: "Nouvelle requête de prise en charge",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  import : {
    fetchUserStructuresTypesApi,
    fetchDirectionsListApi,
    fetchDivisionsListApi,
    fetchDepartmentsListApi,
    fetchServicesListApi,
    fetchTakeAwayMealRequestDestinationsApi,
    fetchTakeAwayMealRequestExternalDestinationsApi,
    fetchMealsTypesApi,
    fetchIdentityDocTypesApi,
    fetchSupportTypesApi
  },
  data() {
    return {
      title: "Modifier Une requête de prise en charge",
      
      destinationTypes: [],
      externalDestinations: [],
      structureTypes: [],
      divisions: [],
      directions: [],
      departments: [],
      services: [],
      structures: [],
      identityDocTypes: [],
      supportTypes: [],
      mealsTypes: [],
      visitorSupportRequestForm: {
        id: "",
        uuid: "",
        date: new Date().toISOString().substr(0, 10),
        dateIn: "",
        dateOut: "",
        needHosting: "",
        needRest: "",
        needTransport: "",
        location_type: "",
        structure_type: "",
        structure: "",
        externalDestination:"",
        externalDestinationTxt:"",
        reason: "",
        analyticAccount: "",
        companyName: "",
        file: "",
        notes: "",
        visitors: [
          {
            fullName: "",
            identityDocType: "",
            identityDocNo: "",
            supportType: "",
          },
        ],
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Requêtes",
          href: "/",
        },
        {
          text: "Prise en charge",
          active: '/',
        },
        {
          text: "Nouveau",
          active: '/',
        },
      ],
    };
  },

  validations: {
    visitorSupportRequestForm: {
      // date: { required },
    },
  },

  created() {
    this.fetchUserStructuresTypes();
    this.fetchDirectionsList();
    this.fetchDivisionsList();
    this.fetchDepartmentsList();
    this.fetchServicesList();
    this.fetchIdentityDocTypes();
    this.fetchSupportTypes();
    this.fetchSingleVisitorSupportRequestData();
  },

  methods: {
    fetchSingleVisitorSupportRequestData() {
      var _this = this;
      this.$http
        .post("/ops/requests/visitors_support/getSingleRequestData/" + this.$route.params.uid)

        .then((res) => {
          var status = res.data.original.status;
          var tamr = res.data.original.data.tamr;
          switch (status) {
            case 200:
                _this.visitorSupportRequestForm.id = tamr.id;
                _this.visitorSupportRequestForm.uuid = tamr.uuid;
                _this.visitorSupportRequestForm.date = tamr.date;
                _this.visitorSupportRequestForm.structure_type = tamr.structureTypeObj;
                _this.visitorSupportRequestForm.reason = tamr.reason;
                _this.visitorSupportRequestForm.analyticAccount = tamr.analytic_account;
                _this.visitorSupportRequestForm.dateIn = tamr.date_from;
                _this.visitorSupportRequestForm.dateOut = tamr.date_to;
                _this.visitorSupportRequestForm.needHosting = tamr.need_hosting;
                _this.visitorSupportRequestForm.needRest = tamr.need_restauration;
                _this.visitorSupportRequestForm.needTransport = tamr.need_transport;
                _this.visitorSupportRequestForm.companyName = tamr.companyName;
                _this.visitorSupportRequestForm.visitors = tamr.entries;
              break;

            case 500:
              _this.$toast.warning(res.data.original.msg)
              break;
          }
        })
        .catch((error) => {
          _this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    onchangeStructure(){
      var form = this.visitorSupportRequestForm;
      var struct = this.visitorSupportRequestForm.structure;
      form.analyticAccount = struct.analytic_account;
    },
    onchangeStructureType(){
      var struct = this.visitorSupportRequestForm.structure_type.id;
      this.structures = [];
      switch (struct) {
        case 'direction':
          this.structures = this.directions;
        break;

        case 'division':
          this.structures = this.divisions;
        break;

        case 'department':
          this.structures = this.departments;
        break;

        case 'service':
          this.structures = this.services;
        break;
      
        default:
          
          break;
      }
    },
    _validateVisitorSupportVisitorsData(){
      var valid = true;
      var visitorsData = this.visitorSupportRequestForm.visitors;
      visitorsData.forEach((singleVisitorEntry, index) => {
        index++;
          if(singleVisitorEntry.fullName == ""){
            this.$toast.warning("Nom du visiteur est obligatoire dans la line N°: " + index);
            valid = false;
          }

          if(singleVisitorEntry.supportType == ""){
            this.$toast.warning("Type de prise en charge du visiteur est obligatoire dans la line N°: " + index);
            valid = false;
          }
      });

      return valid;
    },
    AddformData() {
      if(this._validateVisitorSupportVisitorsData()){
        this.visitorSupportRequestForm.visitors.push({
          fullName: "",
          identityDocType: "",
          identityDocNo: "",
          supportType: "",
        });
      }
    },
    /**
     * Delete the row
     */

    fetchIdentityDocTypes(){
      fetchIdentityDocTypesApi()
      .then((res) => (this.identityDocTypes = res.data.original.data))
              .catch(() => {})
              .finally(() => {});
    },
    fetchSupportTypes(){
      fetchSupportTypesApi()
      .then((res) => (this.supportTypes = res.data.original.data))
              .catch(() => {})
              .finally(() => {});   
    },
    fetchMealsTypes(){
      fetchMealsTypesApi()
      .then((res) => (this.mealsTypes = res.data.original.data))
              .catch(() => {})
              .finally(() => {});
    },
    fetchDirectionsList(){
      fetchDirectionsListApi()
        .then((res) => (this.directions = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDivisionsList(){
      fetchDivisionsListApi()
        .then((res) => (this.divisions = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDepartmentsList(){
      fetchDepartmentsListApi()
        .then((res) => (this.departments = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchServicesList(){
      fetchServicesListApi()
        .then((res) => (this.services = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchUserStructuresTypes() {
      fetchUserStructuresTypesApi()
        .then((res) => (this.structureTypes = res.data.original.data))
        .catch(() => {})
        .finally(() => {});
    },
    deleteRow(index) {
      if (confirm("Êtes-vous sûr de vouloir supprimer ce visiteur?"))
        this.visitorSupportRequestForm.visitors.splice(index, 1);
    },


    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      var router = this.$router;
      this.$http
        .post(
          "/ops/requests/visitors_support/update",
          this.visitorSupportRequestForm
        )
        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "requests.visitors_support.index" });
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit" enctype="multipart/form-data">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Date *</label>
                    <input type="date" v-model="visitorSupportRequestForm.date" class="form-control" name="" id="">
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="formrow-inputCity">Type de structure *</label>
                    <multiselect
                      v-model="visitorSupportRequestForm.structure_type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="structureTypes"
                      placeholder="Select user structure"
                      :allow-empty="false"
                      @input="onchangeStructureType"
                    >
                      <template slot="singleStruct" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-password-input"
                      >Structure d'accueil *</label
                    >
                    <multiselect
                      v-model="visitorSupportRequestForm.structure"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="structures"
                      placeholder="Select user structure"
                      :allow-empty="false"
                      @input="onchangeStructure"
                    >
                      <template slot="singleStruct" slot-scope="{ struct }">{{
                        struct.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-password-input"
                      >Motif de la demande *</label
                    >
                    <input
                      v-model="visitorSupportRequestForm.reason"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="">Imputation *</label>
                    <input type="text" class="form-control" v-model="visitorSupportRequestForm.analyticAccount">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="">Attachement </label>
                    <input type="file" class="form-control">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-password-input"
                      >Date d'arrivée *</label
                    >
                    <input
                      v-model="visitorSupportRequestForm.dateIn"
                      type="date"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="">Date de sortie *</label>
                    <input type="date" class="form-control" v-model="visitorSupportRequestForm.dateOut">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-group">
                    <label for="">Services *</label> <br>
                    <b-form-checkbox
                    switch
                    v-model="visitorSupportRequestForm.needRest"
                    class="mb-3 inlb"
                    >Restauration</b-form-checkbox
                    >
                    <b-form-checkbox
                    v-model="visitorSupportRequestForm.needHosting"
                    switch
                    class="mb-3 inlb"
                    >Hébergement</b-form-checkbox
                    >
                    <b-form-checkbox
                    v-model="visitorSupportRequestForm.needTransport"
                    switch
                    class="mb-3 inlb"
                    >Transport</b-form-checkbox
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">Entreprise *</label>
                    <input type="text" class="form-control" v-model="visitorSupportRequestForm.companyName" name="" id="">
                  </div>
                </div>
              </div>

            <h3>Visiteurs: </h3>
             <hr>  
              <div>
                <div
                  v-for="(visitor, index) in visitorSupportRequestForm.visitors"
                  :key="visitor.id"
                  class="row"
                >
                <div class="form-group col-lg-3">
                    <label for="message">Nom complet</label>
                    <input
                      id="subject"
                      v-model="visitor.fullName"
                      type="text"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-lg-3">
                    <label for="resume">Pièce d'identité</label>
                    <multiselect
                      v-model="visitor.identityDocType"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="identityDocTypes"

                      placeholder="Selectionner"
                      :allow-empty="false"
                    >
                      <template slot="singleMealType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>

                  <div class="form-group col-lg-2">
                    <label for="subject">N° Identité</label>
                    <input
                      id="subject"
                      v-model="visitor.identityDocNo"
                      type="text"
                      class="form-control"
                    />
                  </div>

                  <div class="form-group col-lg-3">
                    <label for="message">Type de prise en charge</label>
                    <multiselect
                      v-model="visitor.supportType"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="supportTypes"
                      placeholder="Selectionner"
                      :allow-empty="false"
                    >
                      <template slot="singleMealType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                  </div>

                  <div class="col-lg-1 align-self-center">
                    <a
                      v-if="
                        visitorSupportRequestForm.visitors.length > 1 &&
                        index != 0
                      "
                      href="javascript:void(0)"
                      ><i class="bx bx-trash-alt" @click="deleteRow(index)"></i
                    ></a>
                  </div>
                </div>
                <input
                  type="button"
                  class="btn btn-success mt-3 mt-lg-0"
                  value="Ajouter"
                  @click="AddformData"
                />
              </div>
              <br />
              <!-- <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-password-input">Attachement</label>
                    <input type="file" class="form-control" />
                  </div>
                </div>
              </div> -->

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Commentaires</label>
                    <textarea
                      class="form-control"
                      v-model="visitorSupportRequestForm.notes"
                      cols="30"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div>
                <button :disabled="$v.visitorSupportRequestForm.$invalid" class="btn btn-primary" type="submit">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>